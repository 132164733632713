var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"lg":"12"}},[_c('b-overlay',{attrs:{"show":_vm.overlayStatus,"rounded":"sm"}},[_c('b-card',[_c('b-card-title',{staticClass:"card-header"},[_c('h2',[_vm._v("Vehículos en tiempo real")]),_c('b-button',{staticClass:"btn-icon",attrs:{"id":"realod","variant":"gradient-primary","size":"sm"},on:{"click":function($event){return _vm.getLastLocations()}}},[_c('feather-icon',{attrs:{"icon":"RefreshCwIcon"}})],1)],1),_c('h5',[_vm._v("Próxima actualización en "+_vm._s(_vm.countDown)+" segundos...")]),(_vm.data.length > 0)?_c('GmapMap',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":15,"options":{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUI: false
                }}},_vm._l((_vm.data),function(vehicle,indexV){return _c('div',{key:indexV},_vm._l((vehicle.locations),function(location,indexL){return _c('GmapMarker',{key:indexL,attrs:{"position":location.position,"clickable":true},on:{"click":function($event){return _vm.openWindow(indexV, indexL, location.position)}}},[_c('GmapInfoWindow',{attrs:{"opened":location.window},on:{"closeclick":function($event){location.window=false}}},[_c('h5',[_vm._v(_vm._s(vehicle.name))]),_c('hr'),_c('p',[_c('b',[_vm._v("Ultima conexion:")]),_vm._v(" "+_vm._s(_vm.$moment.unix(location.lastConnection).format('DD/MM/YYYY h:mm a')))]),_c('p',[_c('b',[_vm._v("Bateria:")]),_vm._v(" "+_vm._s(location.battery)+"%")]),(location.speed > 0)?_c('p',[_c('b',[_vm._v("Velocidad:")]),_vm._v(" "+_vm._s(location.speed)+" km/h")]):_c('p',[_c('b',[_vm._v("Tiempo parado:")]),_vm._v(" "+_vm._s(location.stopTimeMinute))]),_c('p',[_c('b',[_vm._v("Estatus:")]),_vm._v(" "+_vm._s(location.status))]),_c('hr'),_c('p',[_c('a',{attrs:{"href":'https://www.google.com/maps/dir/?api=1&destination=' + location.position.lat + ','+ location.position.lng,"target":"_blank"}},[_vm._v("Ir al sitio")])])])],1)}),1)}),0):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }