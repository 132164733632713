<template>
<!-- v-if="$can('read', 'Analytics')" -->
  <div >
    <b-row class="match-height">
      <b-col lg="12">
        <b-overlay
          :show="overlayStatus"
          rounded="sm"
        >
          <b-card>
            <b-card-title class="card-header">
              <h2>Vehículos en tiempo real</h2>
              <b-button
                id="realod"
                variant="gradient-primary"
                size="sm"
                class="btn-icon"
                @click="getLastLocations()"
              >
                <feather-icon icon="RefreshCwIcon" />
              </b-button>
            </b-card-title>
              <h5>Próxima actualización en {{ countDown }} segundos...</h5>
              <GmapMap
                v-if="data.length > 0"
                :center='center'
                :zoom='15'
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUI: false
                }"
                style='width:100%;  height: 400px;'
              >
                <div
                  :key="indexV"
                  v-for="(vehicle, indexV) in data"
                >
                  <GmapMarker
                    :key="indexL"
                    v-for="(location, indexL) in vehicle.locations"
                    :position="location.position"
                    :clickable="true"
                    @click="openWindow(indexV, indexL, location.position)"
                  >
                    <!-- :icon="markerOptions" -->
                    <GmapInfoWindow
                      @closeclick="location.window=false" 
                      :opened="location.window"
                    >
                      <h5>{{ vehicle.name }}</h5>
                      <hr>
                      <p><b>Ultima conexion:</b> {{ $moment.unix(location.lastConnection).format('DD/MM/YYYY h:mm a') }}</p>
                      <p><b>Bateria:</b> {{ location.battery }}%</p>
                      <p v-if="location.speed > 0"><b>Velocidad:</b> {{ location.speed }} km/h</p> 
                      <p v-else><b>Tiempo parado:</b> {{ location.stopTimeMinute }}</p>
                      <p><b>Estatus:</b> {{ location.status }}</p>
                      <hr>
                      <p><a 
                        :href="'https://www.google.com/maps/dir/?api=1&destination=' + location.position.lat + ','+ location.position.lng" target="_blank">Ir al sitio</a></p>
                    </GmapInfoWindow>
                  </GmapMarker>
                </div>
              </GmapMap>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
  
</template>


<script>
import {
  BCard, BCardText, BCardBody, BCardTitle, BCardSubTitle, 
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, 
  BButton, BAlert, BTable, BOverlay, VBTooltip, BPagination, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import data from '@/router/routes/ui-elements'


import utils from '../../utils/utils.js';


export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    vSelect,
    BTable,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BPagination,
    BTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {

      // Maps
      lastIndexV: null,
      lastIndexL: null,
      center: {
        lat: 25.7160864590491,
        lng: -100.30284827058581,
      },
      currentPlace: null,
      places: [],
      overlayStatus: true,
      countDown: 60,

      // Marker
      markerOptions: {
        url: require('../../assets/images/vehicles/gps/pickup.png'),
        scaledSize: {width: 50, height: 50, f: 'px', b: 'px',},
      },

      // Data
      data: []
    }
  },
  created() {
    this.getLastLocations();
    // this.geolocate();
    this.timer = setInterval(this.getLastLocations, 60000);
    this.countDownTimer = setInterval(this.countDownTimer, 1000);

  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    clearInterval(this.countDownTimer);
    next();
  },
  methods: {
    getLastLocations() {
      this.$axios.get(this.$services + 'gps/get_location').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // Set Data
          this.data = res.data.data;
          this.data[0].locations[0].window = true;  // Abrir ventana
          this.center = this.data[0].locations[0].position;  // Centrar

          // Clear intervals
          // clearInterval(this.timer);
          // clearInterval(this.countDownTimer);
          
          // // New timers
          // this.timer = setInterval(this.getLastLocations, 15000);
          // this.countDownTimer = setInterval(this.countDownTimer, 1000);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
        this.overlayStatus = false;
      });
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    openWindow(indexV, indexL, position) {
      if (this.lastIndexV != null || this.lastIndexL != null)
        this.data[this.lastIndexV].locations[this.lastIndexL].window = false; 

      this.lastIndexV = indexV;
      this.lastIndexL = indexL;

      this.data[indexV].locations[indexL].window = true;
      this.center = position;      
    },
    countDownTimer() {
      if(this.countDown == 0) {
        this.countDown = 59;
      } else {
        this.countDown -= 1
      }
    }
  },
}
</script>
